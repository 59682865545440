/*
 * Layout Name: Cards CTA
 * Desc:
 */
import React from "react"
import { Image } from "react-datocms"
import Link from "next/link"
import * as styles from "./CardsCTA.module.scss"
// components
import Section from "@components/structure/Section/Section"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"

const CardsCTA = (props) => {
  const cards = props.cards?.map((card, i) => (
    <div key={i} className={styles.card}>
      <a href={card.link} target="_blank" rel="noreferrer">
        <Image className={styles.image} data={card.backgroundImage.responsiveImage} />
        <div className={styles.text}>
          <h2>{card.title}</h2>
          <span>{card.cta}</span>
        </div>
      </a>
    </div>
  ))

  return (
    <Section className={styles.CardsCTA}>
      <SiteWrapper narrow>
        <div className={styles.grid}>{cards}</div>
      </SiteWrapper>
    </Section>
  )
}

export default CardsCTA
