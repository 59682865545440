/*
 * Layout Name: How Hopp Works
 *
 * Desc:
 */
import cn from "classnames"
import "keen-slider/keen-slider.min.css"
import { useKeenSlider } from "keen-slider/react"
import ReactMarkdown from "react-markdown"
import styles from "./HowHoppWorks.module.scss"
import useWindowSize from "@lib/useWindowSize"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import Section from "@components/structure/Section/Section"

const HowHoppWorks = (props) => {
  const { width } = useWindowSize()

  const [sliderRef] = useKeenSlider({
    slides: {
      origin: "center",
      perView: 1.5,
    },
    breakpoints: {
      "(min-width: 480px)": {
        slides: {
          origin: "center",
          perView: 2,
        },
      },
      "(min-width: 768px)": {
        disabled: true,
      },
    },
  })

  return (
    <Section className={styles.HowHoppWorks}>
      <h2>{props.title}</h2>
      <div className={cn("keen-slider", { [styles.grid]: width >= 768 })} ref={sliderRef}>
        {props.features.map((feature, i) => {
          return (
            <div className="keen-slider__slide" key={i}>
              <div className={styles.feature}>
                <img src={feature.image} alt="" />
                <div className={styles.text}>
                  <span>{i + 1}</span>
                  <h3>{feature.title}</h3>
                  <ReactMarkdown>{feature.text}</ReactMarkdown>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.buttons}>
        <AuthenticationModal
          defaultStep="createParty"
          button={{
            text: "Take Party Quiz",
            color: "pink",
            design: "outline",
          }}
        />
      </div>
    </Section>
  )
}

export default HowHoppWorks
