import { useQuerySubscription } from "react-datocms"
import { getSubscription, queries } from "@data/datocms"
import { loadOptionsData } from "@data/options"
import Layout from "@components/structure/Layout/layout"
import CardsCTA from "@components/layouts/home/CardsCTA/CardsCTA"
import FeaturedSuppliers from "@components/layouts/home/FeaturedSuppliers/FeaturedSuppliers"
import HomeHero from "@components/layouts/home/HomeHero/HomeHero"
import HowHoppWorks from "@components/layouts/home/HowHoppWorks/HowHoppWorks"
import JoinHopp from "@components/layouts/home/JoinHopp/JoinHopp"
import LatestPosts from "@components/layouts/home/LatestPosts/LatestPosts"
import LatestProductEdits from "@components/layouts/home/LatestProductEdits/LatestProductEdits"
import PopularParties from "@components/layouts/home/PopularParties/PopularParties"
import Seo from "@components/structure/Seo/Seo"
import worksCelebrate from "@images/pages/home/worksCelebrate.svg"
import worksCreate from "@images/pages/home/worksCreate.svg"
import worksEnjoy from "@images/pages/home/worksEnjoy.svg"

export default function Index({ subscription, options }) {
  const {
    data: { seo, global, landingPages, featuredSuppliers, latestEdits, latestPosts },
  } = useQuerySubscription(subscription)

  const content = {
    homeHero: {
      sections: global.homeHero,
      slider: global.homeSlider,
      featuredVideo: {
        src: global.homeBackgroundVideo.url,
        poster: global.homeBackgroundVideoPoster.url,
      },
      title: global.homeTitle,
      subtitle: global.homeSubtitle,
      tagLine: global.homeText,
      inputs: {
        type: {
          label: "Type",
          placeholder: "What party are you planning?",
        },
        where: {
          label: "Where",
          placeholder: "Location of party",
        },
        submit: {
          text: global.homeButtonLabel,
        },
        submitImage: {
          text: "Supplier Gallery",
        },
        submitVirtual: {
          text: "Plan your virtual party",
        },
      },
    },
    howHoppWorks: {
      title: "How HOPP Works",
      features: [
        {
          image: worksCelebrate,
          title: "What are you celebrating?",
          text: "Take our party quiz to start dreaming up your party!",
        },
        {
          image: worksCreate,
          title: "What do you need?",
          text: "We’ll match you with the suppliers best fit for your look & feel",
        },
        {
          image: worksEnjoy,
          title: "Enjoy your exclusive HOPP perks!",
          text: "**Yes, it’s that simple!**",
        },
      ],
    },
    joinHopp: {
      title: global.joinTitle,
      subtitle: global.joinSubtitle,
      text: global.joinText,
      image: global.joinImage,
      icons: global.joinIcons,
    },
    featuredSuppliers: {
      title: "Discover our incredible community of leading suppliers",
      subtitle: "SUPPLIERS",
      suppliers: featuredSuppliers,
    },
    popularParties: {
      title: "Party Types",
      text: "Whether you need a party planner or calligrapher we have curated a black book of suppliers to suit every type of event. Check out our guides.",
      backgroundImage: "",
      landingPages: landingPages,
      button: {
        text: "Start Planning",
      },
    },
    latestEdits: {
      edits: latestEdits,
    },
    latestPosts: {
      title: "Top of the HOPPS",
      posts: latestPosts,
    },
    cardsCTA: {
      cards: global.cardsCta,
    },
  }

  return (
    <Layout options={options}>
      <Seo
        global={seo}
        title="House of Party Planning"
        description="Plan the perfect party, wedding, or corporate event with our incredible range of premium suppliers, and hundreds of discounts only available with HOPP. Start now."
      />
      <HomeHero {...content.homeHero} />
      <HowHoppWorks {...content.howHoppWorks} />
      <PopularParties {...content.popularParties} />
      <FeaturedSuppliers {...content.featuredSuppliers} />
      {/* <LatestProductEdits {...content.latestEdits} /> */}
      <LatestPosts {...content.latestPosts} />
      <JoinHopp {...content.joinHopp} />
      <CardsCTA {...content.cardsCTA} />
    </Layout>
  )
}

export async function getStaticProps() {
  const graphqlRequest = {
    query: `
      {
        ${queries.seo}
        ${queries.home}
        ${queries.global}
      }
    `,
  }

  const subscription = await getSubscription(graphqlRequest)

  const options = await loadOptionsData()

  return {
    props: {
      subscription,
      options,
    },
  }
}
