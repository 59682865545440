/*
 * Layout Name: Cards CTA
 * Desc:
 */
import React from "react"
import * as styles from "./LatestPosts.module.scss"
import PostsList from "@components/layouts/blog/PostsList/PostsList"
import Button from "@components/objects/Button/Button"
import Section from "@components/structure/Section/Section"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"

const LatestPosts = ({ title, posts }) => {
  return (
    <Section className={styles.LatestPosts}>
      <SiteWrapper narrow>
        <h2>{title}</h2>
        <PostsList posts={posts} />
        {title ? (
          <Button className={styles.cta} color="pink" text="View All" link="/top-tips" design="outline" />
        ) : null}
      </SiteWrapper>
    </Section>
  )
}

export default LatestPosts
