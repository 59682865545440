/*
 * Layout Name: Featured Suppliers
 * Desc:
 */
import { useEffect, useState } from "react"
import * as styles from "./FeaturedSuppliers.module.scss"
import useMediaQuery from "@lib/useMediaQuery"
import Button from "@components/objects/Button/Button"
import Section from "@components/structure/Section/Section"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import SupplierList from "@components/supplier/SupplierList/SupplierList"

const FeaturedSuppliers = (props) => {
  const isMobile = useMediaQuery(800)
  const [isSlider, setSlider] = useState(true)

  useEffect(() => {
    setSlider(isMobile)
  }, [isMobile])

  return (
    <Section className={`${styles.FeaturedSuppliers}`}>
      <SiteWrapper className={styles.wrapper}>
        {props.title ? (
          <div className={styles.header}>
            <h3 className={styles.subtitle}>{props.subtitle}</h3>
            <h2 className={styles.title}>{props.title}</h2>
          </div>
        ) : null}
        <SupplierList viewStyle="grid" className={styles.wide} isSlider={isSlider} suppliers={props.suppliers} />

        {props.title ? (
          <Button className={styles.cta} color="pink" text="Start Browsing" link="/suppliers-search" design="outline" />
        ) : null}
      </SiteWrapper>
    </Section>
  )
}

export default FeaturedSuppliers
