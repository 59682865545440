/*
 * Layout Name: Popular Parties
 * Desc:
 */
// components
import cn from "classnames"
import "keen-slider/keen-slider.min.css"
import { useKeenSlider } from "keen-slider/react"
import React, { useState } from "react"
import { Image } from "react-datocms"
import Link from "next/link"
import * as styles from "./PopularParties.module.scss"
import Section from "@components/structure/Section/Section"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"

const PopularParties = ({ title, text, landingPages }) => {
  const [sliderLoaded, setSliderLoaded] = useState(false)

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    mode: "free-snap",
    slides: {
      origin: "center",
      perView: 1.2,
      spacing: 10,
    },
    breakpoints: {
      "(min-width: 480px)": {
        slides: {
          origin: "center",
          perView: 2,
          spacing: 10,
        },
      },
      "(min-width: 800px)": {
        slides: {
          origin: "center",
          perView: 3.5,
          spacing: 20,
        },
      },
      "(min-width: 1099px)": {
        slides: {
          origin: "center",
          perView: 4,
          spacing: 30,
        },
      },
      "(min-width: 1599px)": {
        slides: {
          origin: "center",
          perView: 5,
          spacing: 30,
        },
      },
    },
    created() {
      setSliderLoaded(true)
    },
  })

  const sliderMove = (direction) => {
    const curSlider = slider.current
    const amount = Math.floor(curSlider.options.slides.perView / 2) * direction
    curSlider.moveToIdx(curSlider.track.details.rel + amount)
  }

  return (
    <section className={styles.PopularParties}>
      <Section className={styles.section}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h2>{title}</h2>
            <div className={styles.text}>
              <SiteWrapper className={styles.navWrapper}>
                <p>{text}</p>
              </SiteWrapper>
              <div className={styles.nav}>
                {sliderLoaded && slider.current && (
                  <SiteWrapper className={styles.navWrapper}>
                    <button className={`${styles.prev} `} onClick={() => sliderMove(-1)} />
                    <button className={`${styles.next} `} onClick={() => sliderMove(1)} />
                  </SiteWrapper>
                )}
              </div>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={cn(styles.slider, "keen-slider")} ref={sliderRef}>
              {landingPages && landingPages.length
                ? landingPages.map((landingPage) => (
                    <div key={landingPage.id} className={cn("keen-slider__slide")}>
                      <div className={cn(styles.card)}>
                        <Link href={`/category/${landingPage.slug}`}>
                          <a className={styles.link}>
                            <div className={styles.image}>
                              <Image data={landingPage.featuredImage.responsiveImage} alt={landingPage.shortTitle} />
                            </div>
                            <div className={styles.text}>
                              <h3>{landingPage.shortTitle}</h3>
                            </div>
                          </a>
                        </Link>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Section>
    </section>
  )
}

export default PopularParties
