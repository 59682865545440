import React, { useEffect, useState } from "react"
import { Image } from "react-datocms"
import Link from "next/link"
import * as styles from "./ProductArchiveCard.module.scss"

const ProductArchiveCard = ({ edit, className }) => {
  const firstWord = edit.title.split(" ")[0]
  const restOfTitle = edit.title.substring(edit.title.indexOf(" "))

  return (
    <div className={`${styles.ProductArchiveCard} ${className} ${!edit.featuredImage ? styles.noImage : ""}`}>
      <Link href={`/products/${edit.slug}`}>
        <a>
          {edit.featuredImage ? (
            <Image
              data={edit.featuredImage.responsiveImage}
              className={styles.image}
              pictureClassName={styles.picture}
              alt={edit.title}
            />
          ) : null}
          <div className={styles.title}>
            {edit.formatTitle ? (
              <h2>
                <span>{firstWord}</span>
                {restOfTitle}
              </h2>
            ) : (
              <h2>{edit.title}</h2>
            )}
          </div>
        </a>
      </Link>
    </div>
  )
}

export default ProductArchiveCard
