/*
 * Layout Name: Home Hero
 * Desc:
 */
import { connect } from "react-redux"
import * as styles from "./HomeHero.module.scss"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import Button from "@components/objects/Button/Button"
import NewPartyModal from "@components/party/NewPartyModal/NewPartyModal"


const HomeHero = (props) => {
  return (
    <section className={styles.HomeHero}>
      {props.sections.map((section, i) => (
        <div className={styles.section} key={section.id}>
          <div className={styles.image}>
            <img src={section.image.url + "?fm=jpg&auto=compress&fit=crop&w=1000&h=1000"} alt="" />
          </div>
          <div className={styles.text}>
            <div className={styles.textInner}>
              <h1 className={styles.title}>{section.title}</h1>
              <p>{section.text}</p>
              {i === 0 && (
                <Button
                  color="pink"
                  design="outline"
                  text="Find Supplier"
                  link="/suppliers-search"
                />
              )}
              {i === 1 && !props.isAuthenticated && (
                <AuthenticationModal
                  defaultStep="createParty"
                  button={{
                    text: "Take Quiz",
                    color: "pink",
                    design: "outline",
                    className: styles.button,
                  }}
                />
              )}
              {i === 1 && props.isAuthenticated && (
                <NewPartyModal
                  button={{
                    text: "Take Quiz",
                    color: "pink",
                    design: "outline",
                    className: styles.button,
                  }}
                />
              )}
     
            </div>
          </div>
        </div>
      ))}
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps)(HomeHero)
