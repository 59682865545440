/*
 * Layout Name: Join Hopp
 *
 * Desc:
 */
import React from "react"
import { Image } from "react-datocms"
import SVG from "react-inlinesvg"
import ReactMarkdown from "react-markdown"
import styles from "./JoinHopp.module.scss"
import Button from "@components/objects/Button/Button"

const JoinHopp = ({ title, subtitle, text, image, icons }) => {
  return (
    <div className={styles.JoinHopp}>
      <div className={styles.grid}>
        <Image data={image.responsiveImage} className={styles.image} pictureClassName={styles.picture} alt="Join Hopp?" />
        <div className={styles.content}>
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <div className={styles.icons}>
            {icons &&
              icons.map((item) => (
                <div key={item.label} className={styles.icon}>
                  <SVG src={item.icon.url} />
                  <span>{item.label}</span>
                </div>
              ))}
          </div>
          <ReactMarkdown>{text}</ReactMarkdown>
          <div className={styles.buttons}>
            <Button text="Sign up" link="/become-a-supplier" design="outline" color="pink" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinHopp
